export function formatFiveDigitZipCode(zipCode?: string): string {
  if (!zipCode) {
    return "";
  }

  if (zipCode.length <= 5) {
    return zipCode;
  }

  return zipCode.substring(0, 5);
}

export default function formatZipCode(zipCode?: string): string {
  if (!zipCode) {
    return "";
  }

  if (zipCode.length <= 5) {
    return zipCode;
  }

  // insert "-" at index 5
  return zipCode.substring(0, 5) + "-" + zipCode.substring(5);
}
