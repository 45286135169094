/**
 * toTitleCase
 * @param text
 * @returns text in Title Case (uppercases the first letter of each word)
 */
function toTitleCase(text?: string): string {
  if (!text) {
    return "";
  }

  return text.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export default toTitleCase;
