import { NineActions, NineButton } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";

import { IconLargePlusPharmacyMedicalCupSnakeBowlOfHygieia } from "src/constants/icons";
import { formatPhoneNumberNational } from "src/lib/formatPhoneNumber";
import formatZipCode from "src/lib/formatZipCode";
import toTitleCase from "src/lib/toTitleCase";
import PatientPharmacyBloc from "src/state/PatientPharmacyBloc/PatientPharmacyBloc";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Data from "src/ui/components/Data/Data";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import {
  AppPopup,
  useAppQueryPopup
} from "../AppQueryPopups/AppQueryPopupsBloc";
import Translate from "src/ui/components/Translate/Translate";

export const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PROFILE_PHARMACY_INFO_ROUTE = "/app/profile/pharmacy";

const PharmacyInformationDetail: FC = () => {
  const [{ loading, preferredPharmacy }, { loadPreferredPharmacy }] =
    useBloc(PatientPharmacyBloc);

  useEffect(() => {
    void loadPreferredPharmacy();
  }, []);

  const [openSelect] = useAppQueryPopup(AppPopup.profileSelectPharmacy, {
    onClose: () => {
      void loadPreferredPharmacy();
    }
  });

  return (
    <div>
      <OverlayBackButton show alwaysUseFallback fallbackUrl={PROFILE_URL} />
      <AppPageTitle title="Pharmacy"></AppPageTitle>
      <nine-spacer s={"xl"}></nine-spacer>

      {!preferredPharmacy && !loading && (
        <>
          <PlaceholderWrapper>
            <IconLargePlusPharmacyMedicalCupSnakeBowlOfHygieia />
            <p className="color-c-60 as-subhead strong m0">
              <Translate msg="pharmacy.notAdded" />
            </p>
          </PlaceholderWrapper>

          <nine-spacer s={"sm"}></nine-spacer>
          <NineActions style={{ justifyContent: "center" }}>
            <NineButton onClick={() => openSelect()}>
              <Translate msg="pharmacy.add" />
            </NineButton>
          </NineActions>
        </>
      )}

      <AsyncContent height="20rem" check={[loading === false]}>
        {preferredPharmacy && (
          <Data.Table
            title={<>My retail pharmacy</>}
            action={{ onAction: openSelect }}
          >
            <Data.Row title="Name" data={preferredPharmacy.pharmacyName} />
            <Data.Row
              title="Street"
              data={toTitleCase(preferredPharmacy.address1)}
            />
            {preferredPharmacy.address2 && (
              <Data.Row
                title="App/Suite"
                data={toTitleCase(preferredPharmacy.address2)}
              />
            )}
            <Data.Row title="City" data={toTitleCase(preferredPharmacy.city)} />
            <Data.Row title="State" data={preferredPharmacy.state} />
            <Data.Row
              title="ZIP"
              data={formatZipCode(preferredPharmacy.zipCode)}
            />
            <Data.Row
              title="Phone"
              data={formatPhoneNumberNational(
                preferredPharmacy.primaryPhone,
                false
              )}
            />
          </Data.Table>
        )}
      </AsyncContent>
    </div>
  );
};

export default PharmacyInformationDetail;
